import { defineModule, nextTick } from '@/js/utils/helpers';

const onFormComplete = (
  event: Record<string, any>,
  form: HTMLFormElement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  response: { errors: string[]; content: string; pass: boolean },
) => {
  const formId = form.querySelector<HTMLInputElement>(
    'input[name="form_id"]',
  )?.value;
  const formContainerElement = document.getElementById(
    `frm_form_${formId}_container`,
  );
  if (!formContainerElement) return;

  // Reset placeholders
  nextTick(() => {
    formContainerElement
      .querySelectorAll<
        HTMLInputElement | HTMLTextAreaElement
      >('input:not([type="checkbox"],[type="radio"],[type="hidden"]), textarea')
      .forEach((input) => {
        if (input.placeholder.trim().length) return;
        input.placeholder = ' ';
      });
  });

  // Add completed class to form
  formContainerElement.classList.add('form--completed');

  // Add completed class to content block
  formContainerElement
    .closest<HTMLElement>('.content-block')
    ?.classList.add('content-block--form-completed');

  // Add completed class to modal
  formContainerElement
    .closest('.modal')
    ?.classList.add('modal--form-completed');
};

export default defineModule(
  () => {
    jQuery(document).on('frmFormComplete', onFormComplete);
  },
  () => {
    jQuery(document).off('frmFormComplete', onFormComplete);
  },
);
